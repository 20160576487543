html {
  overflow-y: scroll;
}

body {
  font-size: 18px;
  font-family: Rubik;
  font-weight: 400;
  /* font-family: Arimo; */
  /* font-family: Fredoka; */
}

::-webkit-scrollbar {
  width: 5px;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fffffa;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d1ce;
  border-radius: 30px;
  transition: 300ms;
}

/* HALTU RED - #ff4e5b */
.btn-danger {
  background-color: #ff4e5b;
  border-color: #ff4e5b;
}

.btn-outline-danger {
  color: #ff4e5b;
  border-color: #ff4e5b;
}

.bg-danger {
  background-color: #ff4e5b;
  border-color: #ff4e5b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #343432;
}
::-webkit-scrollbar-track:hover {
  background: #6b6b68;
  border-radius: 30px;
}

.file-input {
  cursor: pointer;
  transition: 400ms;
}
.file-input:hover {
  color: #d1d1ce;
  background: #3e3c3ce3;
}

.user-image-overlay {
  position: absolute;
  /* top: 16px; */
  /* right: 68px; */
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  transition: 0.5s ease;
  opacity: 0;
  cursor: pointer;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-image-input {
  cursor: pointer;
  border-radius: 50%;
  transition: 400ms;
  background: none;
}

.user-image-overlay:hover {
  opacity: 1;
}

.spinner-size {
  height: 20px;
  width: 20px;
}

.file-image-overlay {
  position: absolute;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 7rem;
  height: 10rem;
  border-radius: 8px;
  transition: 0.5s ease;
  opacity: 0;
  cursor: pointer;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-image-overlay:hover {
  opacity: 1;
}

.order-toggle-btn {
  cursor: pointer;
  font-size: 20px;
  border-radius: 8px;
  font-weight: 600;
  text-align: center;
  transition: 500ms;
}

.hiw-btn {
  font-weight: 700;
  transition: 0.3s ease-in;
  border-bottom: 3px solid #6b6b68;
}

.hiw-btn:hover {
  color: #ff4e5b;
  border-color: #ff4e5b;
}

.hiw-btn-active {
  color: #ff4e5b;
  font-weight: 700;
  transition: 0.3s ease-in;
  border-bottom: 3px solid #ff4e5b;
}

.link-item :hover {
  transition: 0.2s;
  color: #ff4e5b;
}

.close-btn :hover {
  transition: 0.2s;
  color: black;
}

/* .category-max-width {
  max-width: 230px;
} */

.category-card {
  color: #343a40;
  box-shadow: none;
  transition: 0.5s;
}

.category-card:hover {
  color: #ff4e5b;
  border: "none";
  transform: scale(1.05);
  box-shadow: 0px 0px 0px 3px #ff4e5b;
}

.terms-header {
  color: #ff4e5b;
}

.terms-underline {
  font-weight: 600;
  text-decoration: underline;
}

.main-feed-feature {
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  transition: 0.5s;
}
.main-feed-feature:hover {
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.9);
  transition: 0.5s;
}
